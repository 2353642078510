import React from 'react';
import Title from '../../components/Title';
import { useProfileContext } from '../../contexts/ProfileContext';
import { useThemeColorContext } from '../../contexts/ThemeContext';

function Testimonial(): JSX.Element {
  const profile = useProfileContext();
  const theme = useThemeColorContext();

  const reviews = profile.reviews.map((review, idx) => ({
    id: `review-id-${idx}`,
    ...review,
  }));

  const defaultReview = reviews.length > 0 ? reviews[0] : null;
  const [activeReview, setActiveReview] = React.useState(defaultReview);

  if (!activeReview) {
    return <></>;
  }

  return (
    <div className="w-full py-8">
      <div className="container mx-auto px-2 lg:px-0">
        <Title text="Testimonials" />

        <div className="text-center">
          <div className="h-48 flex items-center">
            <h1 className={`italic font-semibold text-5xl ${theme.text500} mb-12 mx-auto w-full lg:w-3/5`}>
              &quot;
              {activeReview.quote}
              &quot;
            </h1>
          </div>
          <h2 className="font-bold text-xl">
            {activeReview.reviewer}
          </h2>
          <h3 className="text-lg">
            {activeReview.jobTitle}
            {' '}
            at
            {' '}
            {activeReview.companyName}
          </h3>
        </div>

        <div className="flex flex-row justify-center space-x-2 pt-4">
          {reviews.slice(0, 3).map((review) => {
            const isActive = review.id === activeReview.id;
            const backgroundColor = isActive ? theme.bg500 : theme.bg200;

            return (
              <span
                className={`${backgroundColor} rounded-full h-4 w-4 hover:bg-gray-200`}
                onClick={() => setActiveReview(review)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
