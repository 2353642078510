export type ThemeColor = 'indigo' | 'sky' | 'emerald';
export const THEME_COLORS = ['indigo', 'sky', 'emerald'];

const COLORS = {
  sky: {
    text100: 'text-sky-100',
    text200: 'text-sky-200',
    text300: 'text-sky-300',
    text400: 'text-sky-400',
    text500: 'text-sky-500',
    text600: 'text-sky-600',
    text700: 'text-sky-700',
    text800: 'text-sky-800',
    hoverDark: 'hover:text-sky-600',
    hoverLight: 'hover:text-sky-200',
    bg100: 'bg-sky-100',
    bg200: 'bg-sky-200',
    bg300: 'bg-sky-300',
    bg400: 'bg-sky-400',
    bg500: 'bg-sky-500',
    bg600: 'bg-sky-600',
    bg700: 'bg-sky-700',
    bg800: 'bg-sky-800',
    borderLight: 'border-sky-200',
    borderDark: 'border-sky-500',
    hexLight: '',
    hexDark: '#0ea4e9',
  },
  emerald: {
    text100: 'text-emerald-100',
    text200: 'text-emerald-200',
    text300: 'text-emerald-300',
    text400: 'text-emerald-400',
    text500: 'text-emerald-500',
    text600: 'text-emerald-600',
    text700: 'text-emerald-700',
    text800: 'text-emerald-800',
    hoverDark: 'hover:text-emerald-600',
    hoverLight: 'hover:text-emerald-200',
    bg100: 'bg-emerald-100',
    bg200: 'bg-emerald-200',
    bg300: 'bg-emerald-300',
    bg400: 'bg-emerald-400',
    bg500: 'bg-emerald-500',
    bg600: 'bg-emerald-600',
    bg700: 'bg-emerald-700',
    bg800: 'bg-emerald-800',
    borderLight: 'border-emerald-200',
    borderDark: 'border-emerald-500',
    hexLight: '',
    hexDark: '#10b981',
  },
  indigo: {
    text100: 'text-indigo-100',
    text200: 'text-indigo-200',
    text300: 'text-indigo-300',
    text400: 'text-indigo-400',
    text500: 'text-indigo-500',
    text600: 'text-indigo-600',
    text700: 'text-indigo-700',
    text800: 'text-indigo-800',
    hoverDark: 'hover:text-indigo-600',
    hoverLight: 'hover:text-indigo-200',
    bg100: 'bg-indigo-100',
    bg200: 'bg-indigo-200',
    bg300: 'bg-indigo-300',
    bg400: 'bg-indigo-400',
    bg500: 'bg-indigo-500',
    bg600: 'bg-indigo-600',
    bg700: 'bg-indigo-700',
    bg800: 'bg-indigo-800',
    borderLight: 'border-indigo-200',
    borderDark: 'border-indigo-500',
    hexLight: '',
    hexDark: '#6365f1',
  },
};

export default COLORS;
